import { Model } from '../Model';
import api from './claraplayer';
import {
  PENDANTS,
} from '../pendants';
// const PDF_API_URL = 'http://localhost:3000/mythreekit/pdf';
// const EMAIL_API_URL = 'http://localhost:3000/mythreekit/email';
const PDF_API_URL = 'https://hangout-lighting.herokuapp.com/mythreekit/pdf';
const EMAIL_API_URL = 'https://hangout-lighting.herokuapp.com/mythreekit/email';
const isChrome = !!window.chrome;
const CAMERA_NAME = 'Camera_Main';
const BULB_INFO = {
  Bulb: {
    'Antique Edison 40/60w':{ 'Wattage':'40w or 60w','Lumens':'125 or 230','Temp':'2200K','Dimensions':'6" x 3.5"'},
    'Antique Edison 40w':{ 'Wattage':'40w','Lumens':125,'Temp':'2200K','Dimensions':'6" x 3.5"'},
    'Antique Edison 60w':{ 'Wattage':'60w','Lumens':230,'Temp':'2200K','Dimensions':'6" x 3.5"'},
  'Antique Victorian':{ 'Wattage':'40w','Lumens':125,'Temp':'2200K','Dimensions':'110mm x 60mm'},
  'Antique Radio':{ 'Wattage':'40w','Lumens':125,'Temp':'2200K','Dimensions':'4.6" x 2.3"'},
  'Antique 3" Spiral Globe':{ 'Wattage':'40w','Lumens':125,'Temp':'2200K','Dimensions':'4" x 3"'},
  'Antique 5" Globe':{ 'Wattage':'40w','Lumens':125,'Temp':'2200K','Dimensions':'6" x 5"'},
  'Antique 7" Tube':{ 'Wattage':'40w','Lumens':125,'Temp':'2200K','Dimensions':'7.4" x 1.2"'},
  'LED 7" Tube':{ 'Wattage':'4w','Lumens':440,'Temp':'2200K','Dimensions':'7.4" x 1.2"'},
  'LED Edison':{ 'Wattage':'4w','Lumens':440,'Temp':'2200K','Dimensions':'6" x 3.5"'},
  'LED Victorian':{ 'Wattage':'4w','Lumens':440,'Temp':'2200K','Dimensions':'4.3" x 2.3"'},
  'LED Radio':{ 'Wattage':'4w','Lumens':440,'Temp':'2200K','Dimensions':'4.6" x 2.3"'},
  'LED 3" Globe':{ 'Wattage':'4w','Lumens':440,'Temp':'2200K','Dimensions':'4" x 3"'},
  'LED 5" Globe':{ 'Wattage':'4w','Lumens':440,'Temp':'2200K','Dimensions':'6" x 5"'},
  'LED 3" Clear Globe':{ 'Wattage':'4w','Lumens':440,'Temp':'3500K','Dimensions':'4" x 3"'},
  'LED 3" Clear Globe -3500k':{ 'Wattage':'4w','Lumens':440,'Temp':'3500K','Dimensions':'4" x 3"'},
  'LED 3" Clear Globe -2700k':{ 'Wattage':'4w','Lumens':440,'Temp':'2700K','Dimensions':'4" x 3"'},
  'LED 5" Clear Globe':{ 'Wattage':'4w','Lumens':440,'Temp':'3500K','Dimensions':'6" x 5"'},
  'LED 5" Clear Globe -3500k':{ 'Wattage':'4w','Lumens':440,'Temp':'3500K','Dimensions':'6" x 5"'},
  'LED 5" Clear Globe -2700k':{ 'Wattage':'4w','Lumens':440,'Temp':'2700K','Dimensions':'6" x 5"'},
  'LED 5" Milk White Globe':{ 'Wattage':'4w','Lumens':440,'Temp':'3500K','Dimensions':'6" x 5"'},
  'LED Milk White Edison':{ 'Wattage':'4w','Lumens':440,'Temp':'3500K','Dimensions':'6" x 3.5"'},
  'LED Milk White 4" Tube':{ 'Wattage':'4w','Lumens':440,'Temp':'3500K','Dimensions':'4" x 1"'},
  'LED Smoke Edison':{ 'Wattage':'4w','Lumens':275,'Temp':'3500K','Dimensions':'5.5" x 2.5"'},
  'LED Smoke 3" Globe':{ 'Wattage':'4w','Lumens':275,'Temp':'3500K','Dimensions':'5" x 3"'},
  'LED Smoke 7" Tube':{ 'Wattage':'4w','Lumens':275,'Temp':'3500K','Dimensions':'7" x 1"'},
  'LED Smoke 5" Globe':{ 'Wattage':'4w','Lumens':275,'Temp':'3500K','Dimensions':'7" x 5"'},
  'LED Diamond':{ 'Wattage':'4w','Lumens':440,'Temp':'2200K','Dimensions':'7" x 5"'},
  'Dipped Copper 5" LED Globe':{ 'Wattage':'4w','Lumens':440,'Temp':'2700K','Dimensions':'6" x 5"'},
  'Dipped Gold 5" LED Globe':{ 'Wattage':'4w','Lumens':440,'Temp':'2700K','Dimensions':'6" x 5"'},
  'Dipped Chrome 5" LED Globe':{ 'Wattage':'4w','Lumens':440,'Temp':'2700K','Dimensions':'6" x 5"'},
  'Dipped Black 5" LED Globe':{ 'Wattage':'4w','Lumens':440,'Temp':'2700K','Dimensions':'6" x 5"'},
  'LED Smoke Uneven 5" Globe':{ 'Wattage':'4w','Lumens':440,'Temp':'3500K','Dimensions':'7" x 5"'},
  'LED Uneven 5" Globe':{ 'Wattage':'4w','Lumens':440,'Temp':'2200K','Dimensions':'7" x 5"'},
  'LED Amber XL Beehive':{ 'Wattage':'4w','Lumens':440,'Temp':'2200K','Dimensions':'15" x 8"'},
  'LED Amber XL Uneven Pear':{ 'Wattage':'4w','Lumens':440,'Temp':'2200K','Dimensions':'11" x 7"'},
  'LED Amber XL Pear':{ 'Wattage':'4w','Lumens':440,'Temp':'2200K','Dimensions':'11" x 7"'},
  'LED Amber XL 11" Tube':{ 'Wattage':'4w','Lumens':440,'Temp':'2200K','Dimensions':'11" x 1.25"'},
  'LED Amber XL 8" Globe':{ 'Wattage':'4w','Lumens':440,'Temp':'2200K','Dimensions':'10" x 8"'},
  'LED Amber XL Oval':{ 'Wattage':'4w','Lumens':440,'Temp':'2200K','Dimensions':'14" x 7"'},
  'LED Amber XL Turret':{ 'Wattage':'4w','Lumens':440,'Temp':'2200K','Dimensions':'12" x 3"'},
  'LED Amber XL Teardrop':{ 'Wattage':'4w','Lumens':440,'Temp':'2200K','Dimensions':'15" x 3.5"'},
  'LED Amber XL Mushroom':{ 'Wattage':'4w','Lumens':440,'Temp':'2200K','Dimensions':'8" x 6"'},
  'LED Amber XL Uneven Bottle':{ 'Wattage':'4w','Lumens':440,'Temp':'2200K','Dimensions':'12" x 4.5"'},
  'LED Amber XL Gourd':{ 'Wattage':'4w','Lumens':440,'Temp':'2200K','Dimensions':'9" x 7"'},
  'LED Smoke XL Uneven Pear':{ 'Wattage':'4w','Lumens':440,'Temp':'3500K','Dimensions':'11" x 7"'},
  'LED Smoke XL UFO':{ 'Wattage':'4w','Lumens':440,'Temp':'3500K','Dimensions':'6" x 7.5"'},
  'LED Smoke XL Bubbles':{ 'Wattage':'4w','Lumens':440,'Temp':'3500K','Dimensions':'9" x 4"'},
  'LED Smoke XL Bottle':{ 'Wattage':'4w','Lumens':440,'Temp':'3500K','Dimensions':'10" x 3"'},
  'LED Smoke XL Lined Teardrop':{ 'Wattage':'4w','Lumens':440,'Temp':'3500K','Dimensions':'11" x 5"'},
  'LED Smoke XL Antler':{ 'Wattage':'4w','Lumens':440,'Temp':'3500K','Dimensions':'15" x 9"'},
  'LED White Diamond':{ 'Wattage':'4w','Lumens':440,'Temp':'3500K','Dimensions':'7" x 5"'},
  'LED Clear Edison':{ 'Wattage':'4w','Lumens':440,'Temp':'3500K','Dimensions':'6" x 3.5"'},
  'LED Clear Edison -3500k':{ 'Wattage':'4w','Lumens':440,'Temp':'3500K','Dimensions':'6" x 3.5"'},
  'LED Clear Edison -2700k':{ 'Wattage':'4w','Lumens':440,'Temp':'2700K','Dimensions':'6" x 3.5"'},
  'LED White 2" Globe':{ 'Wattage':'4w','Lumens':220,'Temp':'3500K','Dimensions':'3" x 2"'},
  'LED White 3" Globe':{ 'Wattage':'4w','Lumens':440,'Temp':'3500K','Dimensions':'4" x 3"'},
  'LED 2" Clear Globe -2700k':{ 'Wattage':'4w','Lumens':220,'Temp':'2700K','Dimensions':'3" x 2"'},

  }};







  const SKU_CODES = {
    Product: {
      'Wood Chandelier - 18" Square': 'w1',
      'Wood Chandelier - 18" Round': 'w2',
      'Wood Chandelier - 35" x 9"': 'w5',
      'Wood Chandelier - 35" x 12"': 'w6',
      'Wood Chandelier - 47" x 9"': 'w8',
      'Wood Chandelier - 47" x 12"': 'w9',
      'Wood Chandelier - 24" Round': 'w3',
      'Wood Chandelier - 24" Square': 'w4',
      'Wood Chandelier - 70" x 12"': 'wb',
      'Wood Chandelier - 35" x 18"': 'w7',
      'Wood Chandelier - 47" x 24"': 'wa',
      'Cluster Chandelier - Even': 'ce',
      'Cluster Chandelier - Grape': 'cg',
      'Cluster Chandelier - Staggered': 'cs',
      'Wrap Chandelier': 'ub',
      'Single Pendant': 'sp',
      'Swag Chandelier': 'sw',
      'Metal Chandelier - 12" Round': 'm1',
      'Metal Chandelier - 18" Round': 'm2',
      'Metal Chandelier - 18" Square': 'm3',
      'Metal Chandelier - 24" Round': 'm4',
      'Metal Chandelier - 24" Square': 'm5',
      'Metal Chandelier - 33" Round': 'm6',
      'Metal Chandelier - 36" Square': 'm7',
      'Metal Chandelier - 48" Square': 'm8',
      'Metal Chandelier - 35" x 12"': 'm9',
      'Metal Chandelier - 47" x 12"': 'ma',
      'Metal Chandelier - 70" x 12"': 'mb',
      'Metal Chandelier - 16" Round': 'mc',
      'Flush Mount': 'fm',
      'Beam Wrap - 4 Feet': 'b4',
      'Torch Wall Sconce': 'z1',
      'Hinge Wall Sconce': 'z2',
      'Modular Chandelier - 48" Square':'mz',
      'Modular Chandelier - 36" x 96"':'my',
    },
    'Number of Pendants': {
      1: 1,
      2: 2,
      3: 3,
      4: 4,
      5: 5,
      7: 7,
      9: 9,
      10: 10,
      14: 14,
      16:16,
      17: 17,
      19: 19,
      18: 18,
      25:25,
      27: 27,
    },
    'Ceiling Plate': {
      Black: 'bl',
      Graphite: 'gr',
      Bronze: 'bz',
      Brass: 'bs',
      Antique: 'an',
      Nickel: 'ni',
      Chrome: 'cr',
      Copper: 'cp',
      White: 'wh',
      'Wood Ebony': 'we',
      'Wood Grey': 'wg',
      'Wood Walnut': 'wt',
      'Wood White': 'ww',
      'Wood Natural': 'wn',
      'Plug-in': 'pg',
    },
    'Metal Base': {
      Black: 1,
      Nickel: 2,
      Copper: 3,
      Brass: 4,
      White: 5,
    },
    'Base Color': {
      Black: 1,
      White:5,
    },
    'Wood Type': {
      New: 1,
      Reclaimed: 2,
    },
    'Wood Stain': {
      Ebony: 1,
      Grey: 2,
      Walnut: 3,
      White: 4,
      Natural: 5,
    },
    'Chain Finish': {
      Brass: 1,
      Black: 2,
      Nickel: 3,
    },
    'Bracket Finish': {
      Black: 1,
      Nickel: 2,
    },
    'Hardware Finish': {
      White: 1,
      Copper: 2,
      Black: 3,
      Brass: 4,
    },
    'Cluster Ring': {
      White: 1,
      Copper: 2,
      Black: 3,
      Brass: 4,
      Nickel: 5,
    },
    'Arm Finish': {
      White: 1,
      Copper: 2,
      Black: 3,
      Brass: 4,
      Nickel: 5,
    },
    'Hinge Finish': {
      White: 1,
      Copper: 2,
      Black: 3,
      Brass: 4,
      Nickel: 5,
    },
    'Tee Joint Finish': {
      White: 1,
      Copper: 2,
      Black: 3,
      Brass: 4,
      Nickel: 5,
    },
    'Endcap Finish': {
      White: 1,
      Copper: 2,
      Black: 3,
      Brass: 4,
      Nickel: 5,
    },
    'Ceiling Hooks': {
      'Black Silicone': 1,
      'White Silicone': 2,
      'Black Hook': 3,
      'White Hook': 4,
      'Copper Hook': 5,
      'Nickel Hook': 6,
      'Brass Hook': 7,
      'Black Metal': 8,
      'White Metal': 9,
      'Copper Metal': 'a',
      'Nickel Metal': 'b',
      'Brass Metal': 'c',
      'Antique Metal': 'd',
      'Black Thick': 'e',
      'White Thick': 'f',
      'Black Standard': 'g',
      'White Standard': 'h',
      'Antique Hook': 'i',
    },
    'Cord Type': {
      Round: 'r',
      Twisted: 't',
      Thick: 'h',
      Chain: 'c',
    },
    'Cord Color': {
      Black: 10,
      Brown: 11,
      Berry: 12,
      Purple: 13,
      Navy: 14,
      Teal: 15,
      Graphite: 16,
      Rust: 17,
      Wine: 18,
      Mauve: 19,
      Cobalt: 20,
      Pine: 21,
      Grey: 22,
      'Terracotta Tweed': 23,
      Red: 24,
      Pink: 25,
      Olive: 26,
      White: 27,
      Gold: 28,
      Orange: 29,
      Bubblegum: 30,
      Turquoise: 31,
      Green: 32,
      'Grey Dot': 33,
      Tan: 34,
      Yellow: 35,
      Coral: 36,
      Mint: 37,
      Lime: 38,
      'White Brass': 39,
      Beige: 40,
      Champagne: 41,
      Blush: 42,
      Eucalyptus: 43,
      'Grey Tweed': 44,
      Chevron: 45,
      Rainbow: 46,
      Magenta: 47,
      'Green Tweed': 48,
      'Manila Rope': 49,
      'Cotton Rope': 50,
      'Taupe':51,
      '1 Inch Black Chain': 52,
      '1 Inch Brass Chain': 53,
      '4 Inch Black Chain': 54,
      '4 Inch Gold Chain': 55,
      '4 Inch Slate Chain': 56,
      '4 Inch White Chain': 57,
      '4 Inch Crimson Chain': 58,
      '4 Inch Evergreen Chain': 59,
      '4 Inch Blue Chain': 60,
      '1 Inch Nickel Chain': 61,
      '1 Inch Antique Chain': 62,
    },
    'Cage / Shade': {
      None: 'n',
      Cage: 'c',
      Shade: 's',
    },
    'Shades and Cages': {
      'Glass Globe': 10,
      'Amber Glass Globe': 11,
      'Glass Cylinder': 12,
      'Glass Dome': 13,
      'Glass Cone': 14,
      'Glass Bell': 15,
      'Copper Cone': 16,
      'Antique Cone': 17,
      'Black Cone': 18,
      'Nickel Cone': 19,
      'Black Ruffle': 20,
      'Metal Net': 21,
      'Black Large Flat': 22,
      'Black Diamond': 23,
      'Brass Diamond': 24,
      'Copper Diamond': 25,
      'Chrome Diamond': 26,
      'Mint Diamond': 27,
      'White Diamond': 28,
      'Black Round': 29,
      'Black Hinge': 30,
      'Brass Hinge': 31,
      'Steel Round': 32,
      'Tube Cage': 33,
      'Flare Cage': 34,
      'Radio Cage': 35,
      'Brass Tube Cage':36,
      'White Cone':37,
      'Brass Cone':38,
      'Black 5" Drum':39,
      'Nickel 5" Drum':40,
      'Copper 5" Drum':41,
      'Brass 5" Drum':42,
      'White 5" Drum':43,
      'Black 12" Drum':44,
      'Nickel 12" Drum':45,
      'Copper 12" Drum':46,
      'Brass 12" Drum':47,
      'White 12" Drum':48,
      'Black 16" Industrial':49,
      'Nickel 16" Industrial':50,
      'Copper 16" Industrial':51,
      'Brass 16" Industrial':52,
      'White 16" Industrial':53,
      'Grey Felt Cone':54,
      'Khaki Felt 10" Dome':55,
      'Grey Felt 20" Flat':56,
      'Grey Felt 16" Dome':57,
      'Black Small Flat':58,
      'Glass Small Flat':59,
      'Frosted Glass Cone':60,
      'Black 5" Dome':61,
      'Nickel 5" Dome':62,
      'Copper 5" Dome':63,
      'Brass 5" Dome':64,
      'White 5" Dome':65,
      'Black 12" Dome':66,
      'Black 18" Dome':67,
      'Black 24" Dome':68,
      'Brass 18" Dome': 69,
      'Brass 24" Dome': 70,
      'White 18" Dome': 71,
      'White 24" Dome': 72,
      'Glass 10" Neckless':73,
      'White 10" Neckless':74,
      'Glass 12" Neckless':75,
      'White 12" Neckless':76,
      'Glass 14" Neckless':77,
      'White 14" Neckless':78,
      'Glass 16" Neckless':79,
      'White 16" Neckless':80,
      'Black 24" Disk':81,
      'White 24" Disk':82,
      'Black 5" Disk':83,
      'Brass 5" Disk':84,
      'Black 8" Disk':85,
      'Brass 8" Disk':86,
      'White 8" Disk':87,
      'Black 12" Disk':88,
      'Brass 12" Disk':89,
      'Brass 24" Disk':90,
      'Black Octagon Cage':91,
      'Brass Octagon Cage':92,
      'Black 20" Deep Dome':93,
      'White 20" Deep Dome':94,
      'Grey 20" Deep Dome':95,
      'Bamboo 18" Woven Dome':96,
      'Bamboo 18" Woven Basket':97,
      'Bamboo 18" Woven Round':98,
      'Bamboo 24" Woven Dome':99,
      'Bamboo 24" Woven Basket': 'a1',
      'Bamboo 30" Woven Basket': 'a2',
      'Bamboo 36" Woven Basket': 'a3',
      'Bamboo 24" Woven Round': 'a4',
      'Glass 8" Neckless': 'a5',
      'White 8" Neckless': 'a6',
      'Black/Brass 24" Dome': 'a7',
      'White Neckless Shade Mix': 'a8',

    },  
    'Shade Cover': {
      Black: 1,
      Brass:2,
      Nickel: 3,
      Copper: 4,
      White: 5,
      Antique: 6,
    },
    'Shade Fitter': {
      Antique:1,
      Nickel:2,
      Black:3,
      White:4,
      Bronze:5,
      Copper:6,
      Chrome:7,
      Graphite:8,
      Brass:9,
    },
    Socket: {
      'Modern Black': 10,
      'Modern Steel': 11,
      'Modern Nickel': 12,
      'Modern Copper': 13,
      'Modern Gold': 14,
      'Modern Brass': 15,
      'Modern Antique': 16,
      'Modern White': 17,
      'Vintage Black': 18,
      'Vintage Bronze': 19,
      'Vintage Graphite': 20,
      'Vintage Chrome': 21,
      'Vintage Nickel': 22,
      'Vintage Copper': 23,
      'Vintage Brass': 24,
      'Vintage Antique': 25,
      'Vintage White': 26,
      'Keyed Black': 27,
      'Keyed Bronze': 28,
      'Keyed Graphite': 29,
      'Keyed Chrome': 30,
      'Keyed Nickel': 31,
      'Keyed Copper': 32,
      'Keyed Brass': 33,
      'Keyed Antique': 34,
      'Keyed White': 35,
      'Threaded Black': 36,
      'Threaded Bronze': 37,
      'Threaded Graphite': 38,
      'Threaded Chrome': 39,
      'Threaded Nickel': 40,
      'Threaded Copper': 41,
      'Threaded Antique': 42,
      'Threaded White': 43,
      'Threaded Brass': 44,
      'Wood Ebony': 45,
      'Wood Walnut': 46,
      'Wood Grey': 47,
      'Wood White Wash': 48,
      'Wood Natural': 49,
      'Modern Threaded Black': 50,
      'Modern Threaded Brass': 51,
      'Modern Threaded Copper': 52,
      'Modern Threaded Nickel': 53,
      'Modern Threaded White': 54,
      'Modern Threaded Marble': 55,
    },
    Bulb: {
      'Antique Edison 40w': 10,
      'Antique Edison 60w': 11,
      'Antique Victorian': 12,
      'Antique Radio': 13,
      'Antique 3" Spiral Globe': 14,
      'Antique 5" Globe': 15,
      'Antique 7" Tube': 16,
      'LED 7" Tube': 17,
      'LED Edison': 18,
      'LED Victorian': 19,
      'LED Radio': 20,
      'LED 3" Globe': 21,
      'LED 5" Globe': 22,
      'LED 3" Clear Globe': 23,
      'LED 3" Clear Globe -3500k': 23,
      'LED 5" Clear Globe': 24,
      'LED 5" Clear Globe -3500k': 24,
      'LED 5" Milk White Globe': 25,
      'LED Milk White Edison': 26,
      'LED Milk White 4" Tube': 27,
      'Clear A15': 28,
      'Clear 4" Tube': 29,
      'Clear 3" Globe': 30,
      'Clear 5" Globe': 31,
      'LED Diamond': 32,
      'Dipped Copper 5" LED Globe': 33,
      'Dipped Gold 5" LED Globe': 34,
      'Dipped Chrome 5" LED Globe': 35,
      'Antique Bulb Mix': 36,
      'LED Amber Bulb Mix': 37,
      'Incandescent Clear Bulb Mix': 38,
      'LED Smoke Edison': 39,
      'LED Smoke 7" Tube': 40,
      'LED Smoke 3" Globe': 41,
      'LED Smoke 5" Globe': 42,
      'Dipped Black 5" LED Globe': 43,
      'LED Smoke Uneven 5" Globe':44,
      'LED Uneven 5" Globe':45,
      'LED Amber XL Beehive':46,
      'LED Amber XL Uneven Pear':47,
      'LED Amber XL Pear':48,
      'LED Amber XL 11" Tube':49,
      'LED Amber XL 8" Globe':50,
      'LED Amber XL Oval':51,
      'LED Amber XL Turret':52,
      'LED Amber XL Teardrop':53,
      'LED Amber XL Mushroom':54,
      'LED Amber XL Uneven Bottle':55,
      'LED Amber XL Gourd':56,
      'LED Smoke XL Uneven Pear':57,
      'LED Smoke XL UFO':58,
      'LED Smoke XL Bubbles':59,
      'LED Smoke XL Bottle':60,
      'LED Smoke XL Lined Teardrop':61,
      'LED Smoke XL Antler':62,
      'LED Amber XL Bulb Mix':63,
      'LED Smoke XL Bulb Mix':64,
      'LED White Diamond':65,
      'LED Clear Edison':66,
      'LED Clear Edison -3500k':66,
      'LED White 2" Globe':67,
      'LED White 3" Globe':68,
      'LED 3" Clear Globe -2700k': 69,
      'LED 5" Clear Globe -2700k': 70,
      'LED Clear Edison -2700k':71,
      'LED 2" Clear Globe -2700k': 72,
      'LED Amber XL Antler': 73,
      'LED White XL 8" Globe': 74,
      'LED Clear XL 8" Globe -2700k': 75,
      'LED White Bulb Mix': 76,
      'LED Clear Globe Bulb Mix': 77,
      'LED Amber Globe Bulb Mix': 78,
      'LED White Globe Bulb Mix': 79,
       None: '',
    },
       Length: {
      '1': 10,
      '2': 11,
      '3': 12,
      '4': 13,
      '5': 14,
      '6': 15,
      '7': 16,
      '8': 17,
      '9': 18,
      '10': 19,
      '11': 20,
      '12': 21,
      '13': 22,
      '14': 23,
      '15': 24,
      '16': 25,
      '17': 26,
      '18': 27,
      '19': 28,
      '20': 29,
      '21': 30,
      '24': 31,
      '27': 32,
      '30': 33,
      '33': 34,
      '36': 35,
      '39': 36,
      '42': 37,
      '45': 38,
      '48': 39,
      '51': 40,
      '54': 41,
      '57': 42,
      '60': 43,
      '63': 44,
      '66': 45,
      '69': 46,
      '72': 47,
      '4-6 Feet Staggered': 48,
      '6 Feet': 49,
      '6-10 Feet Staggered': 50,
      '10 Feet': 51,
      '10-15 Feet Staggered': 52,
      '15 Feet': 53,
      '20-35 Staggered': 54,
      '20-48 Staggered': 55,
      '20-72 Staggered': 56,
      'Staggered': 54,
      '84':57,
      '96':58,
      '108':59,
      '120':60,
      '180':61,
      '240':62,
      '20-72 Step':63,
      '20-72 Diagonal Step':64,
      '20-72 Diamond':65,
      '36-120 Staggered': 66,
      '36-180 Staggered': 67,
    },
    'Socket Stem': {
      'None':'',
        'Brass - 3"':10,
        'White - 3"':11,
        'Nickel - 3"':12,
        'Copper - 3"':13,
        'Black - 3"':14,
        'Brass - 5"':15,
        'White - 5"':16,
        'Nickel - 5"':17,
        'Copper - 5"':18,
        'Black - 5"':19,
        'Brass - 7"':20,
        'White - 7"':21,
        'Nickel - 7"':22,
        'Copper - 7"':23,
        'Black - 7"':24,
      },
  };
///PDF related functions
function description() {
 



    let initialValues = {};
  function getBraketSize(numPendants) {
      numPendants = numPendants.toString();
    switch (numPendants) {
      case '3':
        return '24" x 12"';
      case '5':
        return '36"x 12"';
      case '7':
        return '48" x 12"';
      default:
        return '';
    }
  }
  initialValues.product = Model.Product;
  initialValues.descriptiontext = "Introducing our stylish " + Model.Product + ", the perfect addition to elevate any space in your home or office. This versatile and customizable light fixture features a range of options to match your unique taste and style. Choose from various colors, finishes, and cord lengths to create the ideal lighting solution for your space.";
  initialValues.sku = buildSKU();
  initialValues.fixture = [];
  if (Model.hasOwnProperty('Length'))
    initialValues.fixture.push({
      key: 'Pendant Length',
      value: isNaN(Model.Length) ? Model.Length : Model.Length + '"',
    });
  else if (Model.hasOwnProperty('Length(Feet)'))
    initialValues.fixture.push({
      key: 'Pendant Length',
      value: Model['Length(Feet)'] * 12 + '"',
    });
  else if (Model.hasOwnProperty('Fixture Length'))
    initialValues.fixture.push({
      key: 'Pendant Length',
      value: Model['Fixture Length'] + '"',
    });
  if(Model['Product'].indexOf("Swag Chandelier") != -1 || Model['Product'].indexOf("Cluster") != -1){
    if(Model["Number of Pendants"] > 6){
      initialValues.fixture.push({ key: 'Ceiling Plate Diameter', value: '8"' });
    }
    else {
      initialValues.fixture.push({ key: 'Ceiling Plate Diameter', value: '4.75"' });
    }
  }
  if(Model['Product'].indexOf("Wood") != -1){
  initialValues.fixture.push({ key: 'Hanging Wire', value: '72" (2) - Hang up to 32"' });
  }
  if(Model["Product"].indexOf("Beam") != -1) {
    initialValues.fixture.push({ key: 'Chain Length', value: '36"' });
      if(Model["Product"].indexOf("4 Feet") != -1) {
          initialValues.fixture.push({ key: 'Beam Size', value: '6" x 6" x 48"' });
      }
      else if (Model["Product"].indexOf("6 Feet") != -1) {
          initialValues.fixture.push({ key: 'Beam Size', value: '6" x 6" x 72"' });
      }
      initialValues.fixture.push({ key: 'Pendant Length', value: 'Adjustable up to 48"' });
  }
  initialValues.fixture.push({ key: 'Voltage', value: '90V - 240V' });
  initialValues.fixture.push({ key: 'Weight', value: '<50lbs' });
  const cordType = Model['Cord Type'];
  let cordColor =
    cordType === 'Thick'
      ? Model['Thick Cord Type']
      : Model[`${cordType} Cord Color`];
      if (Model['Cord Type'] == 'Chain'){
        cordColor = Model['Chain Size'] + ' ' + Model['Chain Color'];
      }
  const shadeCage =
    Model['Cage / Shade'] === 'None' || !Model['Cage / Shade']
      ? 'None'
      : Model['Cage / Shade'] === 'Shade'
      ? Model.Shade
      : Model.Cage;
      if (Model.Product == 'Flush Mount'|| Model['Product'].indexOf("Wall Sconce") !== -1){
        initialValues.parts = [
          { key: 'Socket', value: Model.Socket },
          { key: 'Shade/Cage', value: shadeCage },
        ];
      }else{
        initialValues.parts = [
          { key: 'Cord Type/Color', value: `${cordType} ${cordColor}`},
          { key: 'Socket', value: Model.Socket },
          { key: 'Shade/Cage', value: shadeCage },
        ];
}
if (Model.hasOwnProperty('Shade') && Model['Shade'].includes('Neckless') && Model.hasOwnProperty('Shade Cover'))
initialValues.parts.push({
  key: 'Neckless Shade Cover',
  value: Model['Shade Cover'],
});
if (Model.hasOwnProperty('Shade') && Model['Shade'].includes('White Neckless Shade Mix') )
  initialValues.parts.push({
    key: 'Shade Mix Includes',
    value: '8", 10", and 12" White Neckless Globe Shades',
  });
  if (Model['Cage / Shade'] === 'Shade' && PENDANTS[0].nodes['ShadeFitter'].config)
    initialValues.parts.push({
      key: 'Shade Fitter',
      value: Model['Shade Fitter'],
    });
  initialValues.bulb =
    Model.Bulb === 'None'
      ? [{ key: 'Name', value: 'None' }]
      : [
          { key: 'Name', value: Model.Bulb },
          {
            key: 'Quantity',
            value: Model.hasOwnProperty('Number of Pendants')
              ? Model['Number of Pendants']
              : '1',
          },
          { key: 'Base', value: 'E26' },
          {
            key: 'Type',
            value: /LED/.test(Model.Bulb) ? 'LED' : 'Incandescent',
          },
        ];
        if(Model.Bulb.indexOf('Mix') === -1){
          if(BULB_INFO['Bulb'].hasOwnProperty(Model.Bulb)){
          initialValues.bulb.push({
            key: 'Wattage',
            value: BULB_INFO['Bulb'][Model.Bulb]['Wattage'],
          },{
            key: 'Lumens',
            value: BULB_INFO['Bulb'][Model.Bulb]['Lumens'],
          },{
            key: 'Temp',
            value: BULB_INFO['Bulb'][Model.Bulb]['Temp'],
          },{
            key: 'Dimensions',
            value: BULB_INFO['Bulb'][Model.Bulb]['Dimensions'],
          },{
            key: 'Voltage',
            value: '120',
          });
        };
      };
  let productType;
  if (initialValues.product === 'Single Pendant' || initialValues.product === 'Flush Mount') productType = 'sp';
  else if (/Swag/.test(initialValues.product)) productType = 'swag';
  else if (/Beam/.test(initialValues.product)) productType = 'beam';
  else if (/Wrap/.test(initialValues.product)) productType = 'wrap';
  else if (/Grape/.test(initialValues.product)) productType = 'grape';
  else if (/Even|Staggered/.test(initialValues.product)) productType = 'even';
  else if (/Wood/.test(initialValues.product)) productType = 'wood';
 else if (/Metal/.test(initialValues.product)) productType = 'metal';
 else if (/Modular/.test(initialValues.product)) productType = 'modular';
  switch (productType) {
    case 'grape':
      initialValues.parts.unshift({
        key: 'Cluster Ring',
        value: Model['Cluster Ring'],
      });
    case 'sp':
    case 'even':
      initialValues.parts.unshift({
        key: 'Ceiling Plate',
        value: Model['Ceiling Plate'],
      });
      break;
    case 'swag':
      initialValues.parts.unshift({
        key: 'Ceiling Hooks',
        value: Model['Ceiling Hooks'],
      });
      initialValues.parts.unshift({
        key: 'Ceiling Plate',
        value: Model['Ceiling Plate'],
      });
      break;
      case 'beam':
      Model['Wood Stain'] &&
        initialValues.parts.unshift({
          key: 'Beam Wood Stain',
          value: Model['Wood Stain'],
        });
        Model['Chain Finish'] &&
          initialValues.parts.unshift({
            key: 'Chain Finish',
            value: Model['Chain Finish'],
          });
          Model['Bracket Finish'] &&
            initialValues.parts.unshift({
              key: 'Bracket Finish',
              value: Model['Bracket Finish'],
            });
    break;
    case 'wrap':
      Model['Hardware Finish'] &&
        initialValues.parts.unshift({
          key: 'Hardware Finish',
          value: Model['Hardware Finish'],
        });
      Model['Wood Stain'] &&
        initialValues.parts.unshift({
          key: 'Wood Stain',
          value: Model['Wood Stain'],
        });
      initialValues.fixture.unshift({
        key: 'Bracket Size',
        value: getBraketSize(Model['Number of Pendants']),
      });
      break;
    case 'wood':
      Model['Wood Type'] &&
        initialValues.parts.unshift({
          key: 'Wood Type',
          value: Model['Wood Type'],
        });
      Model['Wood Stain'] &&
        initialValues.parts.unshift({
          key: 'Wood Stain',
          value: Model['Wood Stain'],
        });
      initialValues.fixture.unshift({
        key: 'Wood Size',
        value: initialValues.product.split(' - ')[1],
      });
      initialValues.parts.unshift({
        key: 'Outgoing Wire',
        value: '60" to Ceiling Plate',
      });
      break;
      case 'metal':
        Model['Metal Base'] &&
        initialValues.parts.unshift({
          key: 'Metal Base',
          value: Model['Metal Base'],
        });
      break;
    default:
  }
return createHtml(initialValues);
}
function createHtml(data) {
  var html = "<h1>"+data.product + "</h1><p>"+data.descriptiontext+"</p><ul>Sku<li>"+data.sku +"</li></ul><ul>Parts";
  for (var i in data.parts) {
    if(data.parts[i].value != undefined){
    html += "<li>" + data.parts[i].key +": " + data.parts[i].value + "</li>"
    }
  }
  html += "</ul><ul>Fixture";
  for (var i in data.fixture) {
    if(data.fixture[i].value != undefined){
      html += "<li>" + data.fixture[i].key +": " + data.fixture[i].value + "</li>"
    }
  }
  html += "</ul><ul>Bulb(s)";
  for (var i in data.bulb) {
    if(data.bulb[i].value != undefined){
      html += "<li>" + data.bulb[i].key +": " + data.bulb[i].value + "</li>"
    }
  }
  html += "</ul>";
  return html;
}


function dataURItoBlob(dataURI) {
  var byteString = atob(dataURI.split(',')[1]);
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: 'image/jpeg' });
}
function takeSnapshot() {
  const cameraId = api.scene.find(CAMERA_NAME);
  api.player.frameScene(undefined, {
    cameraId,
    mode: 'geometry',
    padding: 0.1,
  });
  const snapshot = api.commands.runCommand('snapshot');
  const canvas = document.createElement('canvas');
  canvas.width = 1024;
  canvas.height = 1024;
  canvas.style.display = 'none';
  const ctx = canvas.getContext('2d');
  ctx.fillStyle = '#fff';
  ctx.fillRect(0, 0, 1024, 1024);
  const image = new Image();
  const loadPromise = new Promise(resolve => {
    image.onload = () => {
      ctx.drawImage(image, 0, 0);
      resolve('resolved');
    };
    image.src = URL.createObjectURL(snapshot);
  });
  const form = new FormData();
  const fileName = `HL_${Model.Product}.jpg`;
  form.append('name', fileName);
  form.append('targetFormat', 'jpg');
  return loadPromise.then(() => {
    const jpegUrl = dataURItoBlob(canvas.toDataURL('image/jpeg'));
    form.append('file', jpegUrl);
    return fetch('https://clara.io/api/files/image', {
      method: 'POST',
      body: form,
    })
      .then(res => res.json())
      .then(
        imageData =>
          `https://clara.io/resources/${imageData.hash}?filename=${fileName}`
      );
  });
}
function sendEmail(values) {
  const imgUrlPromise = takeSnapshot();
  createTemplateObject(values);
  imgUrlPromise.then(imgUrl => {
    values.imgUrl = imgUrl;
    fetch(EMAIL_API_URL, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(values),
    });
  });
}
function sendPdf(values) {
  const imgUrlPromise = takeSnapshot();
  createTemplateObject(values);
  imgUrlPromise.then(imgUrl => {
    values.imgUrl = imgUrl;
    fetch(PDF_API_URL, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(values),
    })
      .then(res => {
        return res.json();
      })
      .then(json => {
        const byteCharacters = atob(json.response);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        const fileName = `${values.createdBy}_${values.project}.pdf`;
        //ie
        if (window.navigator && window.navigator.msSaveOrOpenBlob)
          window.navigator.msSaveOrOpenBlob(blob, fileName);
        else {
          const url = URL.createObjectURL(blob);
          const download = document.createElement('a');
          download.href = isChrome
            ? url
            : `data:application/pdf;base64,${[json.response]}`;
          download.download = fileName;
          document.body.appendChild(download);
          download.click();
        }
      });
  });
}

function buildSKU() {
  let sku = '';
  for (let key in SKU_CODES) {
    switch (key) {
      case 'Product':
      case 'Ceiling Plate':
        if ( key==='Ceiling Plate' && (Model['Product'].includes("Metal Chandelier") || Model['Product'].includes('Modular Chandelier'))){
          sku += 'mp-';
        }
      case 'Ceiling Hooks':
      case 'Cluster Ring':
      case 'Bracket Finish':
      case 'Hardware Finish':
      case 'Arm Finish':
      case 'Hinge Finish':
      case 'Tee Joint Finish':
      case 'Endcap Finish':
      case 'Wood Stain':
      if(key==='Wood Stain' && (Model['Product'] === 'Wrap Chandelier' || Model['Product'].indexOf('Beam Wrap') !== -1 )){
        if (Model[key]) sku += SKU_CODES[key][Model[key]];
      }else if (key==='Ceiling Plate' && Model['Product'] === 'Wrap Chandelier') {
        sku += 'mp-'
      }else if ( key == "Arm Finish" ||  key == "Tee Joint Finish"  ){
        if (Model[key]) sku += SKU_CODES[key][Model[key]];
      }

      else{
        if (Model[key]) sku += SKU_CODES[key][Model[key]] + '-';
      }
        break;

      case 'Cord Color':
        if (Model['Product'] == 'Flush Mount' || Model['Product'].indexOf('Wall Sconce') !== -1) {
          sku += '99';
          break;
        }
        if(Model['Cord Type'] === 'Chain')
        {
          sku += SKU_CODES[key][`${Model['Chain Size']} ${Model['Chain Color']} Chain`];
        } else {
           sku +=
           Model['Cord Type'] === 'Thick'
            ? SKU_CODES[key][Model['Thick Cord Type']]
            : SKU_CODES[key][Model[`${Model['Cord Type']} ${key}`]];
        }
        break;
        case 'Wood Type':
        if (!Model[key] && Model.Product.includes("Wood Chandelier")){
            sku+=1;
        }
        else {
          if (Model[key]) sku += SKU_CODES[key][Model[key]];
        }
        break;
        case 'Length' :
        if ( Model['Product'] == 'Flush Mount'){
          break;
        }
          else if ( Model['Product'].includes('Wood Chandelier') ||  Model['Product']=='Swag Chandelier' || Model['Product'].includes('Metal Chandelier') || Model['Product'].includes('Modular Chandelier')  ){
             sku += '-' + SKU_CODES[key][Model['Length']];
          }
          else if( Model['Product'].includes('Cluster Chandelier')){
            sku += '-' + SKU_CODES[key][Model['Fixture Length']];
          }
          else if( Model['Product']=='Single Pendant'){
            sku += '-' + SKU_CODES[key][Model['Length(Feet)']];
          }
        break;
        case 'Metal Base':
          if (Model[key]) {
            sku +=  SKU_CODES[key][Model[key]] + '-';
          }
          break;
          case 'Base Color':
            if (Model["Product"].includes("Modular")) {
              sku +=  SKU_CODES[key][Model[key]] + '-';
            }
            break;
        case 'Shade Fitter':
        if(!Model['Cage / Shade'] || Model['Cage / Shade'] === 'None'){
        break;
        }
        else {
           if (Model[key] && Model[key]!=undefined) sku += SKU_CODES[key][Model[key]];
           break;
        }
      case 'Shades and Cages':
      if (!Model['Cage / Shade']){
        sku += 'n';
        break;
      }
        if ( Model['Cage / Shade'] === 'None') {
      break;
      }
        sku += SKU_CODES[key][Model[Model['Cage / Shade']]];
        break;
      case 'Shade Cover':
        if (Model['Shade'] && Model['Shade'].includes('Neckless')){
          sku += SKU_CODES[key][Model[key]];
        }
        break;
      case 'Number of Pendants':
        if (Model.Product === 'Single Pendant' || Model.Product === 'Flush Mount' ) {
          sku += 1;
          break;
        }
      default:
        if (Model[key]) sku += SKU_CODES[key][Model[key]];
        break;
    }
  }
  return sku;
}


export { description };
