import { Model } from '../Model';
import api from './claraplayer';


  const PRICES = {
    Product: {
      'Wood Chandelier - 18" Square': 319,
      'Wood Chandelier - 18" Round': 319,
      'Wood Chandelier - 35" x 9"': 319,
      'Wood Chandelier - 35" x 12"': 349,
      'Wood Chandelier - 47" x 9"': 349,
      'Wood Chandelier - 47" x 12"': 369,
      'Wood Chandelier - 24" Round': 349,
      'Wood Chandelier - 24" Square': 349,
      'Wood Chandelier - 70" x 12"': 549,
      'Wood Chandelier - 35" x 18"': 569,
      'Wood Chandelier - 47" x 24"': 679,
      'Cluster Chandelier - Even': 159,
      'Cluster Chandelier - Grape': 159,
      'Cluster Chandelier - Staggered': 159,
      'Wrap Chandelier': 399,
      'Single Pendant': 59,
      'Swag Chandelier': 119,
      'Metal Chandelier - 12" Round': 229,
      'Metal Chandelier - 18" Round': 319,
      'Metal Chandelier - 18" Square': 319,
      'Metal Chandelier - 24" Round': 349,
      'Metal Chandelier - 24" Square': 349,
      'Metal Chandelier - 33" Round': 449,
      'Metal Chandelier - 36" Square': 650,
      'Metal Chandelier - 48" Square': 3800,
      'Metal Chandelier - 35" x 12"': 349,
      'Metal Chandelier - 47" x 12"': 369,
      'Metal Chandelier - 70" x 12"': 549,
      'Metal Chandelier - 16" Round': 319,
      'Flush Mount': 49,
      'Beam Wrap - 4 Feet': 1199,
      'Torch Wall Sconce': 79,
      'Hinge Wall Sconce': 79,
      'Modular Chandelier - 48" Square': 2200,
      'Modular Chandelier - 36" x 96"': 3850,
    },
    'Base NOP': {
      'Wood Chandelier - 18" Square': 5,
      'Wood Chandelier - 18" Round': 5,
      'Wood Chandelier - 35" x 9"': 5,
      'Wood Chandelier - 35" x 12"': 5,
      'Wood Chandelier - 47" x 9"': 5,
      'Wood Chandelier - 47" x 12"': 5,
      'Wood Chandelier - 24" Round': 5,
      'Wood Chandelier - 24" Square': 5,
      'Wood Chandelier - 70" x 12"': 5,
      'Wood Chandelier - 35" x 18"': 10,
      'Wood Chandelier - 47" x 24"': 10,
      'Cluster Chandelier - Even': 3,
      'Cluster Chandelier - Grape': 3,
      'Cluster Chandelier - Staggered': 3,
      'Wrap Chandelier': 3,
      'Single Pendant': 1,
      'Swag Chandelier': 2,
      'Metal Chandelier - 12" Round': 3,
      'Metal Chandelier - 18" Round': 5,
      'Metal Chandelier - 18" Square': 5,
      'Metal Chandelier - 24" Round': 5,
      'Metal Chandelier - 24" Square': 5,
      'Metal Chandelier - 33" Round': 5,
      'Metal Chandelier - 36" Square': 5,
      'Metal Chandelier - 48" Square': 10,
      'Metal Chandelier - 35" x 12"': 5,
      'Metal Chandelier - 47" x 12"': 5,
      'Metal Chandelier - 70" x 12"': 5,
      'Metal Chandelier - 16" Round': 5,
      'Flush Mount': 1,
      'Beam Wrap - 4 Feet': 9,
      'Torch Wall Sconce': 1,
      'Hinge Wall Sconce': 1,
      'Modular Chandelier - 48" Square': 9,
      'Modular Chandelier - 36" x 96"': 18,
    },
    'Ceiling Hooks': {
      'Black Silicone': 0,
      'White Silicone': 0,
      'Black Hook': 4,
      'White Hook': 4,
      'Copper Hook': 4,
      'Nickel Hook': 4,
      'Brass Hook': 4,
      'Black Metal': 10,
      'White Metal': 10,
      'Copper Metal': 10,
      'Nickel Metal': 10,
      'Brass Metal': 10,
      'Antique Metal': 10,
      'Black Thick': 0,
      'White Thick': 0,
      'Black Standard': 0,
      'White Standard': 0,
      'Antique Hook':4,
    },
    'Socket Stem': {
      'None':0,
      'Brass - 3"':3,
      'White - 3"':3,
      'Nickel - 3"':3,
      'Copper - 3"':3,
      'Black - 3"':3,
      'Brass - 5"':5,
      'White - 5"':5,
      'Nickel - 5"':5,
      'Copper - 5"':5,
      'Black - 5"':5,
      'Brass - 7"':7,
      'White - 7"':7,
      'Nickel - 7"':7,
      'Copper - 7"':7,
      'Black - 7"':7,
    },
    'Cord Type': {
      Round: 0,
      Twisted: 0,
      Thick: 40,
      Chain: 0,
    },
    'Chain Size':{
      '1 Inch': 40,
      '4 Inch': 60,
    },
    'Shades and Cages': {
      'Glass Globe': 85,
      'Amber Glass Globe': 85,
      'Glass Cylinder': 50,
      'Glass Dome': 50,
      'Glass Cone': 50,
      'Glass Bell': 50,
      'Copper Cone': 50,
      'Antique Cone': 50,
      'Black Cone': 50,
      'Nickel Cone': 50,
      'Black Ruffle': 50,
      'Metal Net': 50,
      'Black Large Flat': 60,
      'Black Diamond': 30,
      'Brass Diamond': 30,
      'Copper Diamond': 30,
      'Chrome Diamond': 30,
      'Mint Diamond': 30,
      'White Diamond': 30,
      'Black Round': 10,
      'Black Hinge': 10,
      'Brass Hinge': 10,
      'Steel Round': 10,
      'Tube Cage': 30,
      'Flare Cage': 30,
      'Radio Cage': 30,
      'Brass Tube Cage':30,
      'White Cone':50,
      'Brass Cone':50,
      'Black 5" Drum':30,
      'Nickel 5" Drum':30,
      'Copper 5" Drum':30,
      'Brass 5" Drum':30,
      'White 5" Drum':30,
      'Black 12" Drum':50,
      'Nickel 12" Drum':50,
      'Copper 12" Drum':50,
      'Brass 12" Drum':50,
      'White 12" Drum':50,
      'Black 16" Industrial':75,
      'Nickel 16" Industrial':75,
      'Copper 16" Industrial':75,
      'Brass 16" Industrial':75,
      'White 16" Industrial':75,
      'Grey Felt Cone':50,
      'Khaki Felt 10" Dome':85,
      'Grey Felt 20" Flat':100,
      'Grey Felt 16" Dome':100,
      'Black Small Flat':30,
      'Glass Small Flat':50,
      'Frosted Glass Cone':60,
      'Black 5" Dome':30,
      'Nickel 5" Dome':30,
      'Copper 5" Dome':30,
      'Brass 5" Dome':30,
      'White 5" Dome':30,
      'Black 12" Dome':50,
      'Black 18" Dome':115,
      'Black 24" Dome':160,
      'Brass 18" Dome': 115,
      'Brass 24" Dome': 160,
      'White 18" Dome': 115,
      'White 24" Dome': 160,
      'Glass 10" Neckless':75,
      'White 10" Neckless':75,
      'Glass 12" Neckless':120,
      'White 12" Neckless':120,
      'Glass 14" Neckless':180,
      'White 14" Neckless':180,
      'Glass 16" Neckless':450,
      'White 16" Neckless':450,
      'Black 24" Disk':100,
      'Brass 24" Disk':100,
      'White 24" Disk':100,
      'Black 5" Disk':30,
      'Brass 5" Disk':30,
      'Black 8" Disk':40,
      'Brass 8" Disk':40,
      'White 8" Disk':40,
      'Black 12" Disk':50,
      'Brass 12" Disk':50,
      'Black Octagon Cage': 30,
      'Brass Octagon Cage': 30,
      'Black 20" Deep Dome': 175,
      'White 20" Deep Dome': 175,
      'Grey 20" Deep Dome': 175,
      'Bamboo 18" Woven Basket': 130,
      'Bamboo 18" Woven Round': 180,
      'Bamboo 24" Woven Basket': 175,
      'Bamboo 30" Woven Basket': 280,
      'Bamboo 36" Woven Basket': 450,
      'Bamboo 24" Woven Round': 280,
      'Glass 8" Neckless': 50,
      'White 8" Neckless': 50,
      'Black/Brass 24" Dome': 160,
      'White Neckless Shade Mix': 85,

    },
    Bulb: {
      'None':0,
      'Antique Edison 40w': 5,
      'Antique Edison 60w': 5,
      'Antique Victorian': 5,
      'Antique Radio': 5,
      'Antique 3" Spiral Globe': 5,
      'Antique 5" Globe': 5,
      'Antique 7" Tube': 5,
      'LED 7" Tube': 15,
      'LED Edison': 15,
      'LED Victorian': 15,
      'LED Radio': 15,
      'LED 3" Globe': 15,
      'LED 5" Globe': 20,
      'LED 3" Clear Globe': 15,
      'LED 3" Clear Globe -3500k': 15,
      'LED 5" Clear Globe': 20,
      'LED 5" Clear Globe -3500k': 20,
      'LED 5" Milk White Globe': 20,
      'LED Milk White Edison': 15,
      'LED Milk White 4" Tube': 15,
      'Clear A15': 5,
      'Clear 4" Tube': 5,
      'Clear 3" Globe': 5,
      'Clear 5" Globe': 10,
      'LED Diamond': 30,
      'Dipped Copper 5" LED Globe': 20,
      'Dipped Gold 5" LED Globe': 20,
      'Dipped Chrome 5" LED Globe': 20,
      'Antique Bulb Mix': 16.5,
      'LED Amber Bulb Mix': 16.5,
      'Incandescent Clear Bulb Mix': 16.5,
      'LED Smoke Edison': 15,
      'LED Smoke 7" Tube': 15,
      'LED Smoke 3" Globe': 15,
      'LED Smoke 5" Globe': 15,
      'Dipped Black 5" LED Globe': 20,
      'LED Smoke Uneven 5" Globe':30,
      'LED Uneven 5" Globe':30,
      'LED Amber XL Beehive':100,
      'LED Amber XL Uneven Pear':60,
      'LED Amber XL Pear':60,
      'LED Amber XL 11" Tube':30,
      'LED Amber XL 8" Globe':100,
      'LED Amber XL Oval':80,
      'LED Amber XL Turret':60,
      'LED Amber XL Teardrop':45,
      'LED Amber XL Mushroom':60,
      'LED Amber XL Uneven Bottle':60,
      'LED Amber XL Gourd':60,
      'LED Smoke XL Uneven Pear':60,
      'LED Smoke XL UFO':60,
      'LED Smoke XL Bubbles':45,
      'LED Smoke XL Bottle':45,
      'LED Smoke XL Lined Teardrop':60,
      'LED Smoke XL Antler':100,
      'LED Amber XL Bulb Mix':54,
      'LED Smoke XL Bulb Mix':34,
      'LED White Diamond':30,
      'LED Clear Edison':15,
      'LED Clear Edison -3500k':15,
      'LED White 2" Globe':15,
      'LED White 3" Globe':15,
      'LED 3" Clear Globe -2700k': 15,
      'LED 5" Clear Globe -2700k': 20,
      'LED Clear Edison -2700k':15,
      'LED 2" Clear Globe -2700k': 10,
      'LED Amber XL Antler': 100,
      'LED White XL 8" Globe': 100,
      'LED Clear XL 8" Globe -2700k': 100,
      'LED White Bulb Mix': 16.5,
      'LED Clear Globe Bulb Mix': 37,
      'LED Amber Globe Bulb Mix': 37,
      'LED White Globe Bulb Mix': 37,
       None: '',
    },
       Length: {
      '1': 0,
      '2': 0,
      '3': 0,
      '4': 0,
      '5': 0,
      '6': 0,
      '7': 1,
      '8': 2,
      '9': 3,
      '10': 4,
      '11': 5,
      '12': 6,
      '13': 7,
      '14': 8,
      '15': 9,
      '16': 10,
      '17': 11,
      '18': 12,
      '19': 13,
      '20': 14,
      '21': 0,
      '24': 0,
      '27': 0,
      '30': 0,
      '33': 0,
      '36': 0,
      '39': 0,
      '42': 0,
      '45': 0,
      '48': 0,
      '51': 0,
      '54': 0,
      '57': 0,
      '60': 0,
      '63': 0,
      '66': 0,
      '69': 0,
      '72': 0,
      '4-6 Feet Staggered': 0,
      '6 Feet': 0,
      '6-10 Feet Staggered': 2,
      '10 Feet': 4,
      '10-15 Feet Staggered': 6,
      '15 Feet': 9,
      '20-35 Staggered':0,
      '20-48 Staggered': 0,
      '20-72 Staggered': 0,
      '36-120 Staggered': 2,
      '36-180 Staggered': 4,
      'Staggered': 0,
      '84':1,
      '96':2,
      '108':3,
      '120':4,
      '180':6,
      '240':8,
    },
  };
///PDF related functions
function generatePrice(part,type,site) {
    var price = 0;
    var nop = 1;
    if(Model["Number of Pendants"]){
    nop = Model["Number of Pendants"];
  }

    if (part === "all" || "All") {

      for (let key in PRICES) {
        switch (key) {
          case 'Product':
            price += PRICES[key][Model['Product']];
            break;
          case 'Base NOP':
            if ( Model["Product"] != "Wrap Chandelier" && nop != 1){
            price += ((nop - PRICES[key][Model['Product']]) * 40);
            }
            else if (Model["Product"] == "Wrap Chandelier"){
            price += ((nop - PRICES[key][Model['Product']]) * 50);
            }
            break;
          case 'Ceiling Hooks':
            if (Model['Product'].includes('Swag')) {
             price += (PRICES[key][Model['Ceiling Hooks']] * nop);
            }
            break;
          case 'Cord Type':
            if (!Model['Product'].includes('Wall') || !Model['Product'].includes('Flush')) {
            price += (PRICES[key][Model['Cord Type']] * nop);
            }
            break;
            case 'Socket Stem':
              if (Model['Socket Stem']) {
               price += (PRICES[key][Model['Socket Stem']] * nop);
              }
              break;
          case 'Shades and Cages':
            if (Model["Cage / Shade"] == 'Shade') {
              price += PRICES[key][Model['Shade']] * nop;
            }
            else if (Model["Cage / Shade"] == 'Cage') {
            price += PRICES[key][Model['Cage']] * nop;
            }
            break;
          case 'Bulb':
            price += PRICES[key][Model['Bulb']] * nop;
            break;
          case 'Length':
          if (site == "mm"){
            let discount = 0.10 * price; // calculating 20% of the price

          let discountedPrice = price - discount; // calculating the price after the discount

          price = Math.floor(discountedPrice);
          }
          var thisLength = Model["Length"] || Model["Length(Feet)"] || Model["Fixture Length"];
          let multiplier = 3;
          if (Model['Cord Type'] === 'Thick'){
            multiplier = 5;
          } else if (Model['Cord Type'] === 'Chain' && Model['Chain Size'] === '4 Inch'){
            multiplier = 6;
          }
       


          if (thisLength ){
            if((thisLength == '15' || thisLength == '18') && Model['Product'] != 'Single Pendant'){

            }
            else{
              price += PRICES[key][thisLength] * nop * multiplier;
            }
                
            }

            break;
            case 'Chain Size':
              
              if (Model['Cord Type'] === 'Chain' && (Model['Product'].indexOf('Flush') === -1  && Model['Product'].indexOf('Wall Sconce') === -1)){
                price += PRICES[key][Model['Chain Size']] * nop;
              }
              break;
          default:
            break;
          }
        }


  return price;









    } else {

    }

   return 0;
}


export { generatePrice };
