import { Model } from '../Model';
import api from '../helpers/claraplayer';
import urlParams from '../helpers/urlParams';
import {
  NODE_IDS,
  fetchedScenes,
  Colors,
  CEILING_PLATE_COLORS,
  VECTORS,
  ROPE_LENGTH,
  SOCKET_TYPES,
  CAGE_TYPES,
  SHADE_SHAPES,
  BULB_SHAPES,
  ROPE_SPECIALS,
  ATTRNAME_NUMBER_PENDANTS,
} from '../constants';
import {
  setNodeMaterial,
  setNodeVisibility,
  getRotation,
  setRotation,
  setTranslation,
  setVisibility,
  setMaterialBaseColor,
  setMaterialReflection,
} from '../helpers/sceneHelpers';
import {
  PENDANTS,
  updatePendantNodes,
  updateTopGripNode,
  updateRopeNode,
  updateCurvedRopeNode,
  updateCeilingHookNode,
  updateCageNode,
  updateShadeNode,
  updateShadeFitterNode,
  updateSocketNode,
  updateBottomGripNode,
  updateBulbNode,
  pendantAttach,
  updateExtrasNode,
  updateSocketStemNode,
} from '../pendants';

const { isInternal } = urlParams;


async function updateExtras() {
  await updateExtrasNode();
  if (Model['Extras'] != 'None'   ) {
 let nodeId = api.scene.find({ name: PENDANTS[0].id });
 let boundingBox = api.scene.getNodeBoundingBox(nodeId);
 if (Model['Extras'] == 'Dining Table - 6 x 4'){
  api.scene.set({name:'Dining Table - 6 x 4', plug:'Transform', property:'translation'}, [0,-(boundingBox.max.y-boundingBox.min.y+.3),0]);
 }
 else if (Model['Extras'] == 'Dining Table - 8 x 4'){
  api.scene.set({name:'Dining Table - 8 x 4', plug:'Transform', property:'translation'}, [0,-(boundingBox.max.y-boundingBox.min.y+.3),0]);

 }
 else if (Model['Extras'] == 'Living Room'){
  api.scene.set({name:'Living Room', plug:'Transform', property:'translation'}, [0,-(boundingBox.max.y-boundingBox.min.y+.6),0]);

 }
 else if (Model['Extras'] == 'Living Room - 2'){


 // api.scene.clone({from: {id: api.scene.find({ name: "Objects"})}}, {withNull: '2nd'});
  //api.scene.set({id: api.scene.find({ name: "2nd"}), plug:'Transform', property:'translation'}, [0,0,.4]);
  //api.scene.set({name:'Living Room - 2', plug:'Transform', property:'translation'}, [0,-(boundingBox.max.y-boundingBox.min.y+.6),0]);

 }
 else if (Model['Extras'] == 'Kitchen Island - 1'){
  api.scene.set({name:'Kitchen Island - 1', plug:'Transform', property:'translation'}, [0,-(boundingBox.max.y-boundingBox.min.y+1.2),0]);

}
}
setNodeVisibility(PENDANTS[0].nodes['Extras'].id,true);



}

async function updateSocketStem(index) {
  if(Model['Socket Stem']){

  await updateSocketStemNode(index);

  const color = Model['Socket Stem'].replace(/ .*/,'');

  let matName = 'M_Stem';
  if (Model['Socket Stem'].indexOf('Black') !== -1 || Model['Socket Stem'].indexOf('White') !== -1  ){
    matName = 'M_StemBW';
  }
const matId = PENDANTS[index].matLib[matName];
  if(matId && PENDANTS[index].nodes['SocketStem'].id){
  setNodeMaterial(
    api.scene.find({
      from: PENDANTS[index].nodes['SocketStem'].id,
      name: `SocketStem`,
    }),
  matId
  );
  setMaterialBaseColor(
    PENDANTS[index].matLib[matName],
    Colors[color]
  );
  }
}
}





async function updateRope(index) {





  await updateRopeNode(index);
  await updateCurvedRopeNode(index);
  const numOfPendants = Model[ATTRNAME_NUMBER_PENDANTS] || 1;
  const cordType = Model[`Cord Type - ${index + 1}`] || Model['Cord Type'];
  //Material assign
  let matId;
  
  if (cordType === 'Thick') {
    matId = PENDANTS[index].matLib['mat_ThickRope_Twisted_Inverse_MP'];
    updateThickCordType(
      index,
      Model[`Thick Cord Type - ${index + 1}`] || Model['Thick Cord Type']
    );
  } else if (cordType === 'Twisted') {
    matId = PENDANTS[index].matLib['Rope Colors_T'];
    updateTwistedCordColor(
      index,
      Model[`Twisted Cord Color - ${index + 1}`] || Model['Twisted Cord Color']
    );
  } else if (cordType === 'Rigid Stem') {
    matId = PENDANTS[index].matLib['M_Rigid Stem'];
    updateRigidStem(
      index,
      Model['Rigid Stem']
    );
  } 
  
  
  else if (cordType === 'Chain') {


//Update Wire Color

const ropeDistance =
      Model[ATTRNAME_NUMBER_PENDANTS] < 9
        ? 'S'
        : Model[ATTRNAME_NUMBER_PENDANTS] === 9
        ? 'M'
        : 'L';
    const curvedRopeName = `Rope_Curve_${cordType}_${ropeDistance}`;
    const wireColor =
      Model[`Round Cord Color - ${index + 1}`] || Model['Round Cord Color'];
    matId = ROPE_SPECIALS.includes(wireColor)
    ? PENDANTS[index].matLib['Rope Fabrics']
    : PENDANTS[index].matLib['Rope Colors_R'];
    if (Model['Chain Size'] === '1 Inch'){
      let oneInchWire = (Model['Chain Color'] ==="Black") ? "Black":"Brass";
     matId = PENDANTS[index].matLib['Wire Chain 1 Inch ' + oneInchWire];
    }

    api.scene
    .filter({
      from: api.scene.find(curvedRopeName),
      name: 'Wire*',
      type:['PolyMesh'],
    })
    .forEach(id => {

      setNodeMaterial(id, matId);
    });
    api.scene
    .filter({
      from: api.scene.find('Straight Rope Chain ' + Model['Chain Size']),
      name: 'Wire*',
      type:['PolyMesh'],
    })
    .forEach(id => {

      setNodeMaterial(id, matId);
    });


    const color =
      Model[`Round Cord Color - ${index + 1}`] || Model['Round Cord Color'];
      updateRoundCordColor(index, color);
  
  

      //Update Chain Color
      matId = PENDANTS[index].matLib['Rope Chain'];
      api.scene
     .filter({
       from: api.scene.find('Straight Rope Chain ' + Model['Chain Size']),
       name: 'Rope*',
       type: ['PolyMesh'],
     })
     .forEach(id => {
 
       setNodeMaterial(id, matId);
     });
     updateChainColor(
      index,
      Model[`Chain Color - ${index + 1}`] || Model['Chain Color']
    );
  }
  else {
    const color =
      Model[`Round Cord Color - ${index + 1}`] || Model['Round Cord Color'];
    matId = ROPE_SPECIALS.includes(color)
      ? PENDANTS[index].matLib['Rope Fabrics']
      : PENDANTS[index].matLib['Rope Colors_R'];
    updateRoundCordColor(index, color);
  }

if(cordType !== 'Chain')
{
  api.scene.setAll(
    {
      from: PENDANTS[index].nodes['Rope'].id,
      type: ['PolyMesh'],
      plug: 'Material',
      property: 'reference',
    },
    matId
  );
}
  
  
  
  if (Model['Product'].indexOf('Wrap') !== -1) {
    const wrapNullName = `Wrap_Rope_${numOfPendants}_${index}`;
    const wrapNullId = api.scene.find(wrapNullName);
    const wrapNodeId = api.scene.find({
      from: wrapNullId,
      name: `Rope_${cordType}_${numOfPendants}_${index}`,
    });
    setVisibility(wrapNullId, false, 'all');
    setNodeVisibility(wrapNodeId, true);
    setNodeMaterial(wrapNodeId, matId);
    if (Model['Product'].indexOf('Beam Wrap') !== -1){
      if (Model['Cord Type'] === 'Round'){
         const outgoingMatId = api.scene.find('M_Outgoing Wire');
        const beamTopNullName = `Wrap_Rope_${numOfPendants}_Base`;
        const beamTopNullId = api.scene.find(beamTopNullName);
        const beamTopNodeId = api.scene.find({
          from: beamTopNullId,
          name: `Outgoing_${cordType}_${numOfPendants}_Top`,
        });
        setNodeVisibility(beamTopNodeId, true);
        setMaterialBaseColor(outgoingMatId, Colors[Model['Ceiling Plate']]);
        setNodeMaterial(beamTopNodeId, outgoingMatId);

      }
    }
  } else if (Model['Product'].indexOf('Swag') !== -1) {
    const curvedRopeNullId = api.scene.find({
      from: PENDANTS[index].id,
      name: 'Rope_Curve',
    });

    api.scene
    .filter({
      from: api.scene.find(curvedRopeNullId),
      name: 'Rope*',
      type:['PolyMesh'],
    })
    .forEach(id => {

      setNodeMaterial(id, matId);
    });


  } else if (Model['Product'].indexOf('Cluster') !== -1) {
    if (Model['Product'].indexOf('Grape') !== -1) {
      const baseId = api.scene.find('Grape_Rope_Base');
      const grapeNullName = `Grape_Rope_Start_${numOfPendants}_${index}`;
      const grapeNullId = api.scene.find({ from: baseId, name: grapeNullName });
      const grapeNodeId = api.scene.find({
        from: grapeNullId,
        name: `Grape_Rope_${cordType}_${numOfPendants}_${index}`,
      });
      setVisibility(grapeNullId, false, 'all');
      setNodeVisibility(grapeNodeId, true);
      setNodeMaterial(grapeNodeId, matId);
      if (!isInternal && index === 0) {
        //Cluster ring re-position
        const ropeLength = Model['Fixture Length'] - 30;
        const y = (ropeLength / 3) * 0.05 + 0.014;
        api.scene.filter({ from: baseId, name: 'Ring*' }).forEach(ringId => {
          setTranslation(ringId, [0, -y, 0]);
          setNodeVisibility(ringId, false);
        });
        const ringSize =
          numOfPendants > 10 ? 'L' : numOfPendants < 6 ? 'S' : 'M';
        const ringId = api.scene.find(
          `Ring_${cordType === 'Thick' ? 'Thick_' : ''}${ringSize}`
        );
        const matId = api.scene.find('M_Cluster Ring');
        setMaterialBaseColor(matId, Colors[Model['Cluster Ring']]);
        setNodeVisibility(ringId, true);
      } else {
        //
      }
    }
  }

}

async function updateChainSize(index) {
  PENDANTS[index].nodes['Rope'].config = 'reset';
}


async function updateTopGrip(index) {
  await updateTopGripNode(index);
  const cordType = isInternal
    ? Model[`Cord Type - ${index + 1}`]
    : Model['Cord Type'];
  const isThick = cordType === 'Thick';
  if (isThick) {
    const tieId = api.scene.find({
      from: PENDANTS[index].nodes['TopGrip'].id,
      name: 'Rope_tie',
    });
    if (tieId)
      setNodeMaterial(
        tieId,
        PENDANTS[index].matLib['mat_Thick_Rope_Start-End']
      );
  }
    else if(cordType === 'Chain'){
      setNodeMaterial(
        api.scene.find({
          from: PENDANTS[index].nodes['TopGrip'].id,
          name: 'Chain_grip_top',
          type: 'PolyMesh',
        }),
        PENDANTS[index].matLib['M_Grip Chain']
      );
      if (Model['Chain Color'] === 'Gold'  || Model['Chain Color'] === 'Brass')
      {
      setMaterialBaseColor(
        PENDANTS[index].matLib['M_Grip Chain'],
        Colors['Brass']
      );
      }
      else{
        let color = Model['Chain Color'];
        if(color == 'Antique'){
          color = 'Chain Antique';
        }
        setMaterialBaseColor(
          PENDANTS[index].matLib['M_Grip Chain'],
          Colors[color]
        );

      }
    }
    else {
    const woodStain = Model['Wood Stain'];
    const metalBase = Model['Metal Base'];
    let value = woodStain ? 'Wood ' + woodStain :  metalBase ? Model['Metal Base'] : Model['Ceiling Plate'] ? Model['Ceiling Plate'] : Model['Base Color'];
    //beam wrap has grips on ceiling plate
    if (Model['Product'].indexOf('Beam Wrap') !== -1){
      value = Model['Ceiling Plate'];
    }
    if (value === 'Antique') {
      setNodeMaterial(
        api.scene.find({
          from: PENDANTS[index].nodes['TopGrip'].id,
          name: 'Grip',
          type: 'PolyMesh',
        }),
        PENDANTS[index].matLib['M_SocketA']
      );
      setMaterialBaseColor(
        PENDANTS[index].matLib['M_SocketA'],
        Colors['Antique']
      );
    } else if (value === 'White' || value === 'Wood White' || value ==='Wood Natural') {
      setNodeMaterial(
        api.scene.find({
          from: PENDANTS[index].nodes['TopGrip'].id,
          name: 'Grip',
          type: 'PolyMesh',
        }),
        PENDANTS[index].matLib['M_CeilingWhite']
      );
    } else if (value === 'Plug-in') {
      setNodeVisibility(
        api.scene.find({
          from: PENDANTS[index].nodes['TopGrip'].id,
          name: 'Grip',
          type: 'PolyMesh',
        }),
        false
      );
    } else {
      setNodeMaterial(
        api.scene.find({
          from: PENDANTS[index].nodes['TopGrip'].id,
          name: 'Grip',
          type: 'PolyMesh',
        }),
        PENDANTS[index].matLib['M_Grip']
      );
    }
    if (value.indexOf('Wood') !== -1) {
      const color = value.split(' ')[1];
      if (color === 'Natural' || color === 'White') {
        setMaterialBaseColor(PENDANTS[index].matLib['M_CeilingWhite'], CEILING_PLATE_COLORS['White']);
      } else if (color === 'Grey') {
        setMaterialBaseColor(
          PENDANTS[index].matLib['M_Grip'],
          Colors['Nickel']
        );
      } else {
        setMaterialBaseColor(PENDANTS[index].matLib['M_Grip'], Colors['Black']);
      }
    } else {
      if (value === 'White') {
          setMaterialBaseColor(
            PENDANTS[index].matLib['M_CeilingWhite'],
            CEILING_PLATE_COLORS[value] || Colors[value]
          );
        }
    else if (value !== 'Antique') {
        setMaterialBaseColor(
          PENDANTS[index].matLib['M_Grip'],
          CEILING_PLATE_COLORS[value] || Colors[value]
        );
      }
    }
    }
}
async function updateCeilingHook(index, value) {
  await updateCeilingHookNode(index);
  if (Model['Product'].indexOf('Swag') !== -1) {
    const ceilingHook = isInternal
      ? Model[`Ceiling Hooks - ${index + 1}`]
      : Model['Ceiling Hooks'];
    const color = ceilingHook.split(' ')[0].trim();
    setMaterialBaseColor(PENDANTS[0].matLib['M_Hook'], Colors[color]);
  }
}
function updateTwistedCordColor(index, color) {
  setVisibility(api.scene.find(PENDANTS[index].nodes['Rope'].id), false, 'all');
  setMaterialBaseColor(PENDANTS[index].matLib['Rope Colors_T'], Colors[color]);
  setVisibility(api.scene.find(PENDANTS[index].nodes['Rope'].id), true, 'all');
}
function updateRoundCordColor(index, color) {
  let matId;
  if (ROPE_SPECIALS.includes(color)) {
    matId = PENDANTS[index].matLib['Rope Fabrics'];
    api.scene.set(
      {
        id: matId,
        plug: 'Material',
        property: 'baseMap',
      },
      api.scene.find(
        `Cordswatch-seamless-${color
          .replace(/ /g, '')
          .trim()
          .toLowerCase()}.jpg`
      )
    );
  } else {
    matId = PENDANTS[index].matLib['Rope Colors_R'];
  }

  if(Model['Cord Type'] !== 'Chain'){
  //Wrap
  api.scene
    .filter({ from: api.scene.find('Wrap Ropes'), name: 'Rope_Round_*' })
    .forEach(id => {
      setNodeMaterial(id, matId);
    });
    //Beam Wrap
    api.scene
      .filter({ from: api.scene.find('Beam Wrap Ropes'), name: 'Rope_Round_*' })
      .forEach(id => {
        setNodeMaterial(id, matId);
      });
  //Grape
  api.scene
    .filter({
      from: api.scene.find('Grape_Rope_Base'),
      name: 'Grape_Rope_Round_*',
    })
    .forEach(id => {
      setNodeMaterial(id, matId);
    });
  //Clones
  api.scene.setAll(
    {
      from: PENDANTS[index].nodes['Rope'].id,
      type: ['PolyMesh'],
      plug: 'Material',
      property: 'reference',
    },
    matId
  );
  api.scene
    .filter({
      from: PENDANTS[index].nodes['CurvedRope'].id,
      type: 'PolyMesh',
      name: 'Rope_Curve_*',
    })
    .forEach(id => {
      setNodeMaterial(id, matId);
    });

  }
  if (!ROPE_SPECIALS.includes(color)) {
    setMaterialBaseColor(matId, {
      r: 0,
      g: 0,
      b: 0,
    });
    setMaterialBaseColor(matId, Colors[color]);
  }
}
function updateThickCordType(index, type) {
  if(type){
  const color = type.split(' ')[0];

  setVisibility(api.scene.find(PENDANTS[index].nodes['Rope'].id), false, 'all');
  setMaterialBaseColor(
    PENDANTS[index].matLib['mat_ThickRope_Twisted_Inverse_MP'],
    Colors[color]
  );
  setMaterialBaseColor(
    PENDANTS[index].matLib['mat_Thick_Rope_Start-End'],
    Colors[color]
  );
  setVisibility(api.scene.find(PENDANTS[index].nodes['Rope'].id), true, 'all');
  }
}

function updateChainColor(index, type) {
  if(type){

  let color = type.split(' ')[0];
  
  setVisibility(api.scene.find(PENDANTS[index].nodes['Rope'].id), false, 'all');
  if (color != 'Black' && color != 'Brass' && color != 'Antique' && color != 'Nickel' && Model['Chain Size'] == '1 Inch') {
    color ='Black';
    Model['Chain Color'] = 'Black';
  }
  if(color == 'Antique'){
    color = 'Chain Antique';
  }
  setMaterialBaseColor(
    PENDANTS[index].matLib['Rope Chain'],
    Colors[color]
  );
  setVisibility(api.scene.find(PENDANTS[index].nodes['Rope'].id), true, 'all');
  }
}

function updateRigidStem(index, type) {
if (Model['Cord Type'] == 'Rigid Stem'){

  let color = Model['Rigid Stem Finish'];

  setVisibility(api.scene.find(PENDANTS[index].nodes['Rope'].id), false, 'all');
  setNodeMaterial(
    api.scene.find({
      from: PENDANTS[index].nodes['Rope'].id,
      name: 'Rope',
      type: 'PolyMesh',
    }),
    PENDANTS[index].matLib['M_Rigid Stem']
  );
  setMaterialBaseColor(
    PENDANTS[index].matLib['M_Rigid Stem'],
    Colors[color]
  );
  setVisibility(api.scene.find(PENDANTS[index].nodes['Rope'].id), true, 'all');
}
  }

async function updateSocket(index) {
  const value = Model[`Socket - ${index + 1}`] || Model['Socket'];
  await updateSocketNode(index);
  const type = SOCKET_TYPES.find(function(name) {
    return value.includes(name);
  });

  const mat = value
    .replace(type, '')
    .trim()
    .split(' ')[0];

  if (type !== 'Wood') {
    const matName =
      mat === 'Black' || mat === 'White'
        ? 'M_SocketBW'
        : mat === 'Antique'
        ? 'M_SocketA'
        : mat === 'Nickel'
        ? 'M_SocketNickel'
        : mat === 'Graphite'
        ? 'M_SocketGraphite'
        : mat === 'Brass'
        ? 'M_SocketBrass'
        : mat === 'Marble'
        ? 'M_SocketMarble'
        : type === 'Modern Threaded'
        ? 'M_MTSocket'
        : 'M_Socket';
    const matId = PENDANTS[index].matLib[matName];
    setNodeMaterial(
      api.scene.find({
        from: PENDANTS[index].nodes['Socket'].id,
        name: `Socket_${type}`,
      }),
      matId
    );
    setMaterialBaseColor(matId, Colors[mat]);
  } else {
    const matId = api.scene.find(`M_New_${mat}`);
    setNodeMaterial(
      api.scene.find({
        from: PENDANTS[index].nodes['Socket'].id,
        name: 'Socket_Wood',
      }),
      matId
    );
  }
  if(Model['Product'] == 'Torch Wall Sconce'){
    api.scene.setAll(api.scene.filter({from: PENDANTS[index].nodes['Socket'].id, plug: 'Transform', property: 'rotation'}), [180, 0, 0]);
  }
  else {
    const curRotation = getRotation( api.scene.find({
      from: PENDANTS[index].nodes['Socket'].id,
      name: 'Socket_Bulbs',
    }));
    if(curRotation['x'] == 180) {
      api.scene.setAll(api.scene.filter({from: PENDANTS[index].nodes['Socket'].id, plug: 'Transform', property: 'rotation'}), [0, 0, 0]);
    }

  }


}
async function updateBottomGrip(index) {
  await updateBottomGripNode(index);
  const cordType = isInternal
    ? Model[`Cord Type - ${index + 1}`]
    : Model['Cord Type'];
  const isThick = cordType === 'Thick';
  let gripName = 'Grip';
  if (isThick){
    gripName = 'Rope_tie';
  }
  else if (cordType === 'Chain') {
    gripName = 'Chain_grip';
  }
  const gripNodeId = api.scene.find({
    from: PENDANTS[index].nodes['BottomGrip'].id,
    name: gripName,
  });
  if (isThick) {
    setNodeMaterial(
      gripNodeId,
      PENDANTS[index].matLib['mat_Thick_Rope_Start-End']
    );
  } else if (cordType === 'Chain'){
    setNodeMaterial(
      gripNodeId,
      PENDANTS[index].matLib['M_Grip Chain']
    );

    if (Model['Chain Color'] === 'Gold'  || Model['Chain Color'] === 'Brass')
    {
    setMaterialBaseColor(
      PENDANTS[index].matLib['M_Grip Chain'],
      Colors['Brass']
    );
    }
    else{
      let color = Model['Chain Color'];
      if(color == 'Antique'){
        color = 'Chain Antique';
      }
      setMaterialBaseColor(
        PENDANTS[index].matLib['M_Grip Chain'],
        Colors[color]
      );

    }
  } else {
    const value = isInternal ? Model[`Socket - ${index + 1}`] : Model['Socket'];
    if (Model['Shade'] && Model['Shade'].includes('Neckless')) {
      setNodeMaterial(gripNodeId, PENDANTS[index].matLib['M_Shade Cover']);
    }
    else if (Model['Socket Stem']  &&  Model['Socket Stem'] !== 'None'){
      if (Model['Socket Stem'].indexOf('Black') !== -1 || Model['Socket Stem'].indexOf('White') !== -1){
        setNodeMaterial(gripNodeId, PENDANTS[index].matLib['M_StemBW']);
      } else {
        setNodeMaterial(gripNodeId, PENDANTS[index].matLib['M_Stem']);
      }
    }
    else if
    (
      value.indexOf('Wood') !== -1 ||
      value.indexOf('White') !== -1 ||
      value.indexOf('Black') !== -1 ||
      value.indexOf('Marble') !== -1
    ) {
      setNodeMaterial(gripNodeId, PENDANTS[index].matLib['M_SocketBW']);
      const color =
        value.indexOf('White') !== -1 || value.indexOf('Natural') !== -1 || value.indexOf('Marble') !== -1
          ? 'White'
          : 'Black';
      setMaterialBaseColor(PENDANTS[index].matLib['M_SocketBW'], Colors[color]);
    } else if (value.indexOf('Antique') !== -1) {
      setNodeMaterial(gripNodeId, PENDANTS[index].matLib['M_SocketA']);
    } else if (value.indexOf('Graphite') !== -1) {
      setNodeMaterial(gripNodeId, PENDANTS[index].matLib['M_SocketGraphite']);
    }
    else if (value.indexOf('Nickel') !== -1) {
      setNodeMaterial(gripNodeId, PENDANTS[index].matLib['M_SocketNickel']);
    } else if (value.indexOf('Brass') !== -1) {
      setNodeMaterial(gripNodeId, PENDANTS[index].matLib['M_SocketBrass']);
    } else if (value.indexOf('Modern Threaded') !== -1) {
      setNodeMaterial(gripNodeId, PENDANTS[index].matLib['M_MTSocket']);
    } else {
      setNodeMaterial(gripNodeId, PENDANTS[index].matLib['M_Socket']);
    }
  }
}
async function updateCageShadeSwitch(index) {
  const promises = [
    updateCage(index),
    updateShade(index),
    updateShadeFitter(index),
  ];
  await Promise.all(promises);
}
async function updateCage(index) {
  await updateCageNode(index);
  const option = isInternal
    ? Model[`Cage / Shade - ${index + 1}`]
    : Model['Cage / Shade'];
  if (option && option === 'Cage') {
    const value = Model[`Cage - ${index + 1}`] || Model['Cage'];
    const type = CAGE_TYPES.find(function(name) {
      return value.includes(name);
    });
    const mat = value
      .replace(type, '')
      .replace('Cage', '')
      .trim();
    if (type === 'Round' && mat === 'Steel') {
      setMaterialBaseColor(PENDANTS[index].matLib['M_Cage'], Colors['Chrome']);
    } else if (mat !== '') {
      setMaterialBaseColor(PENDANTS[index].matLib['M_Cage'], Colors[mat]);
    } else {
      setMaterialBaseColor(PENDANTS[index].matLib['M_Cage'], Colors['Black']);
    }
    if (type === 'Diamond') {
      const diamondNodeId = api.scene.find({
        from: PENDANTS[index].nodes['Cage'].id,
        name: 'Diamond Cage',
      });
      setVisibility(diamondNodeId, false, 'all');
      const topId = mat === 'Mint' || mat === 'White' ? 1 : 2;
      setVisibility(diamondNodeId, true, `Diamond_${topId}`);
    }
    api.scene
      .filter({ from: PENDANTS[index].nodes['Cage'].id, type: 'PolyMesh' })
      .forEach(id => {
        setNodeMaterial(id, PENDANTS[index].matLib['M_Cage']);
      });
  }
}
async function updateShade(index) {

  await updateShadeNode(index);

  const option = (isInternal )
    ? Model[`Cage / Shade - ${index + 1}`]
    : Model['Cage / Shade'];

  if (option && option === 'Shade') {
    var shade = isInternal ? Model[`Shade - ${index + 1}`] : Model['Shade'];
    if(shade.includes("Shade Mix")){
      shade = PENDANTS[index].nodes['Shade'].config
    }
    const shape = SHADE_SHAPES.find(function(name) {
      return shade.includes(name);
    });

    if (shade.includes('Glass')) {
      const nodeName =
        (shade.includes('Small') || shade.includes('Neckless') ? '' : 'Glass ') + shape + ' Shade';
      const nodeId = api.scene.find({
        from: PENDANTS[index].nodes['Shade'].id,
        name: nodeName,
      });

      if (shade.includes('Amber')) {
        setNodeMaterial(nodeId, api.scene.find('Amber Glass Raugh'));
      } else if (shade.includes('Frosted')) {
        setNodeMaterial(nodeId, api.scene.find('Frosted Glass Raugh'));
      } else {
        setNodeMaterial(nodeId, api.scene.find('Clear Glass Raugh'));
      }
    } else if (shade.includes('Cage')) {
      const nodeName = shape + ' Cage';
      const nodeId = api.scene.find({
        from: PENDANTS[index].nodes['Shade'].id,
        name: nodeName,
      });
      const matId = PENDANTS[index].matLib['M_Cage'];
      const color = shade
        .replace(shape, '')
        .replace('Cage', '')
        .trim();
      if (color === '') {
        setMaterialBaseColor(matId, Colors['Black']);
      } else {
        setMaterialBaseColor(matId, Colors[color]);
      }
      setNodeMaterial(nodeId, matId);
    } else if (shade.includes('Felt')) {
      const nodeName = shape + ' Shade';
      const nodeIdInside = api.scene.find({
        from: PENDANTS[index].nodes['Shade'].id,
        name: nodeName + '_Inside',
      });
      const nodeId = api.scene.find({
        from: PENDANTS[index].nodes['Shade'].id,
        name: nodeName,
      });
      const color = shade.replace(shape, '').trim();
      let matIdInside = PENDANTS[index].matLib['M_ShadeFelt-Inside'];
      let matId = PENDANTS[index].matLib['M_ShadeFelt-Grey'];
      if (color === 'Khaki') {
        matId = PENDANTS[index].matLib['M_ShadeFelt-Khaki'];
      } else {
        matId = PENDANTS[index].matLib['M_ShadeFelt-Grey'];
      }

      setNodeMaterial(nodeId, matId);
      setNodeMaterial(nodeIdInside, matIdInside);
    } else {
      const nodeName = shape + ' Shade';
      const nodeId = api.scene.find({
        from: PENDANTS[index].nodes['Shade'].id,
        name: nodeName,
      });
      let matId = PENDANTS[index].matLib['M_Shade'];
      if (
        shape === 'Cone' ||
        shape === '5" Drum' ||
        shape === '12" Drum' ||
        shape === '16" Industrial' ||
        shape === '5" Dome' ||
        shape === '12" Dome' ||
        shape === '18" Dome'  ||
        shape === '24" Dome'  ||
        shape === '20" Deep Dome' ||
        shape.includes('Woven') ||
        shape.includes('Neckless') ||
        shape.includes('Disk')
      ) {
        const color = shade.replace(shape, '').trim();

        if (color === 'Antique') {
          matId = PENDANTS[index].matLib['M_ShadeA'];
          setMaterialBaseColor(matId, Colors[color]);

        } else if (color ==='Bamboo'){
         
          const nodeName = shape + ' Shade';
          const nodeIdPattern = api.scene.find({
            from: PENDANTS[index].nodes['Shade'].id,
            name: nodeName + '_Pattern',
          });
          matId = PENDANTS[index].matLib['M_ShadeWickerPattern'];
        if(nodeIdPattern){
          setNodeMaterial(nodeIdPattern, matId);
        }
        matId = PENDANTS[index].matLib['M_ShadeWicker'];
         
        } else if (color === 'Brass' && (shape === '18" Dome' || shape === '24" Dome') ) {
          matId = PENDANTS[index].matLib['M_ShadeBrass'];
          const nodeName = shape + ' Shade';
          const nodeIdInside = api.scene.find({
            from: PENDANTS[index].nodes['Shade'].id,
            name: nodeName + '_Inside',
          });
          setNodeMaterial(nodeIdInside, api.scene.find('M_ShadeBrass'));
          setMaterialBaseColor(matId, Colors[color]);
        } else if (color === 'Black/Brass' && (shape === '24" Dome' || shape === '36" Dome') ) {
          matId = PENDANTS[index].matLib['M_ShadeBrass'];
          const nodeName = shape + ' Shade';
          const nodeIdInside = api.scene.find({
            from: PENDANTS[index].nodes['Shade'].id,
            name: nodeName + '_Inside',
          });
          setNodeMaterial(nodeIdInside, api.scene.find('M_ShadeBrass'));
          setMaterialBaseColor(matId, Colors['Brass']);
          matId = PENDANTS[index].matLib['M_Shade'];
          setMaterialBaseColor(matId, Colors['Black']);
        }
           else if (color === 'White' && (shape === '18" Dome' || shape === '24" Dome' || shape.includes('Deep Dome')) ) {
          const nodeName = shape + ' Shade';
          const nodeIdInside = api.scene.find({
            from: PENDANTS[index].nodes['Shade'].id,
            name: nodeName + '_Inside',
          });
          matId = PENDANTS[index].matLib['M_ShadeW'];
          setNodeMaterial(nodeIdInside, matId);

        }  else if (shape === '18" Dome' || shape === '24" Dome') {
          setMaterialBaseColor(matId, Colors[color]);
          const nodeName = shape + ' Shade';
          const nodeIdInside = api.scene.find({
            from: PENDANTS[index].nodes['Shade'].id,
            name: nodeName + '_Inside',
          });
          setNodeMaterial(nodeIdInside, api.scene.find('M_ShadeW'));
          //setting inside to white for 18 and 24 inch
        }
        else if (color === 'White'  || (shape.includes('Disk') && color === 'Black') ) {
          matId = PENDANTS[index].matLib['M_ShadeW'];
          setMaterialBaseColor(matId, Colors[color]);
        } else if (color === 'Brass'){
          matId = PENDANTS[index].matLib['M_ShadeBrass'];
          setMaterialBaseColor(matId, Colors[color]);
        } else if (color === 'Grey'){
          const nodeName = shape + ' Shade';
          const nodeIdInside = api.scene.find({
            from: PENDANTS[index].nodes['Shade'].id,
            name: nodeName + '_Inside',
          });
          matId = PENDANTS[index].matLib['M_ShadeMatte'];
          setNodeMaterial(nodeIdInside, matId);
          setMaterialBaseColor(matId, Colors[color]);
        } else {
          setMaterialBaseColor(matId, Colors[color]);
        }
      } else {
        setMaterialBaseColor(matId, Colors['Black']);
      }
      setNodeMaterial(nodeId, matId);
    }

    if(shape.includes("Neckless")){
      updateShadeCover(index,Model['Shade Cover']);
    }
    if (shape === 'Net') {
      const netId = api.scene.find({
        from: PENDANTS[index].nodes['Shade'].id,
        name: 'Cage_new_8',
      });
      setNodeMaterial(netId, api.scene.find('M_Net'));
    }
    
  }
}


async function updateShadeCover(index, type) {

  if(type){
    var shade = isInternal ? Model[`Shade - ${index + 1}`] : Model['Shade'];
    if(shade.includes("Shade Mix")){

      shade = PENDANTS[index].nodes['Shade'].config
    }
    const shape = SHADE_SHAPES.find(function(name) {
      return shade.includes(name);
    });
    const nodeNameTop = shape + ' Shade_Top';

    setNodeMaterial(
      api.scene.find({
        from: PENDANTS[index].nodes['Shade'].id,
        name: nodeNameTop,
      }),
      PENDANTS[index].matLib['M_Shade Cover']
    );


    let color = Model['Shade Cover'];
    const coverMatId = PENDANTS[index].matLib['M_Shade Cover']


    if(color == 'Black'  || color == 'White'){
      setMaterialReflection(coverMatId, .8,.2);
    }
    else {
      setMaterialReflection(coverMatId, .2,1);
    }
    setMaterialBaseColor(
      coverMatId,
      Colors[Model['Shade Cover']]
    );
  
}
}




async function updateShadeFitter(index) {
  await updateShadeFitterNode(index);
  const option = isInternal
    ? Model[`Cage / Shade - ${index + 1}`]
    : Model['Cage / Shade'];
  if (option && option === 'Shade') {
    const color = Model[`Shade Fitter - ${index + 1}`] || Model['Shade Fitter'];

    if (color === 'Antique') {
      setNodeMaterial(
        api.scene.find({
          from: PENDANTS[index].id,
          type: 'PolyMesh',
          name: 'Shade Fitter',
        }),
        PENDANTS[index].matLib['M_Shade_FitterA']
      );
      setMaterialBaseColor(
        PENDANTS[index].matLib['M_Shade_FitterA'],
        Colors[color]
      );
    } else {
      setNodeMaterial(
        api.scene.find({
          from: PENDANTS[index].id,
          type: 'PolyMesh',
          name: 'Shade Fitter',
        }),
        PENDANTS[index].matLib['M_Shade_Fitter']
      );

      setMaterialBaseColor(
        PENDANTS[index].matLib['M_Shade_Fitter'],
        Colors[color]
      );
    }
  }
}
async function updateBulb(index) {
  await updateBulbNode(index);
}
async function updatePendantView(index) {
  const i = index + 1;
  const promises = [];
  promises.push(updateRope(index));
  promises.push(updateShade(index));
  promises.push(updateCage(index));
  promises.push(updateBulb(index));
  if (Model['Product'].indexOf('Swag') !== -1) {
    promises.push(updateCeilingHook(index));
  }
  promises.push(updateSocket(index));
  promises.push(
    updateShadeFitter(
      index,
      Model[`Shade Fitter - ${i}`] || Model['Shade Fitter']
    )
  );
  promises.push(updateBottomGrip(index));
  promises.push(updateTopGrip(index));
  promises.push(updateSocketStem(index));
  await Promise.all(promises);
}
export {
  updatePendantView,
  updateCeilingHook,
  updateTopGrip,
  updateRope,
  updateTwistedCordColor,
  updateRoundCordColor,
  updateThickCordType,
  updateChainColor,
  updateSocket,
  updateBottomGrip,
  updateCageShadeSwitch,
  updateCage,
  updateShade,
  updateShadeFitter,
  updateBulb,
  updateExtras,
  updateSocketStem,
  updateChainSize,
  updateShadeCover,
  updateRigidStem,
};
